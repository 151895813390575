import * as React from 'react'
import cn from 'classnames'
import { PropsWithoutChildren } from 'src/design-system'

export type RootProps = {
  children: React.ReactNode
} & PropsWithoutChildren<React.HTMLAttributes<HTMLDivElement>>

export const Root: React.VFC<RootProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <header
      className={cn(
        'bg-white sticky z-10 top-0 border-0 border-b border-gray-100 border-solid w-full overflow-x-auto min-h-[3.5rem]',
        className
      )}
      {...restProps}
    >
      <div className="absolute left-0 inset-y-0 flex items-center py-2 pl-6 pr-2 gap-3 min-w-full w-max">
        {children}
      </div>
    </header>
  )
}
