import * as React from 'react'
import cn from 'classnames'

export type HeaderProps = {
  children: React.ReactNode
  className?: string
}

export const Header: React.VFC<HeaderProps> = (props) => {
  const { children, className } = props

  return (
    <h1
      className={cn(
        'font-bold text-gray-900 text-sm flex-shrink-0 whitespace-nowrap flex items-center',
        className
      )}
    >
      {children}
    </h1>
  )
}
