import { WithAnalytics } from 'components/with-analytics'
import React from 'react'
import cn from 'classnames'
import { useIsActive } from '../../utils/use-is-active'

type TabContext = { clickEventName?: string }
const TabContext = React.createContext<TabContext>({} as TabContext)

export type TabsProps = {
  children: React.ReactNode
  clickEventName?: string
}

export const Tabs: React.VFC<TabsProps> = ({ children, clickEventName }) => {
  return (
    <nav className="flex flex-row gap-1 flex-shrink-0" role="navigation">
      <TabContext.Provider value={{ clickEventName }}>
        {children}
      </TabContext.Provider>
    </nav>
  )
}

export type TabProps = {
  children: React.ReactNode
  exact?: boolean
  href: string
} & Omit<React.HTMLAttributes<HTMLAnchorElement>, 'href' | 'children'>

export const Tab: React.VFC<TabProps> = ({
  children,
  href,
  exact,
  className,
  ...restProps
}) => {
  const { clickEventName } = React.useContext(TabContext)

  const active = useIsActive(href, null, {
    exactMatch: exact,
  })

  return (
    <WithAnalytics
      event={clickEventName}
      params={{ link: { title: children, href } }}
    >
      <a
        href={href}
        className={cn(
          'px-2 py-1 rounded hover:bg-gray-50',
          active ? 'text-theme-40 bg-gray-50' : 'text-gray-600',
          className
        )}
        aria-current={active ? 'page' : undefined}
        data-turbo-frame="content"
        data-turbo-action="advance"
        {...restProps}
      >
        {children}
      </a>
    </WithAnalytics>
  )
}
